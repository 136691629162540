import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// RMWC Styles
import '@material/theme/dist/mdc.theme.min.css';
import '@material/button/dist/mdc.button.min.css';
import '@material/radio/dist/mdc.radio.min.css';
import '@material/layout-grid/dist/mdc.layout-grid.min.css';
import '@material/typography/dist/mdc.typography.min.css';
import '@material/top-app-bar/dist/mdc.top-app-bar.min.css';
import '@material/list/dist/mdc.list.min.css';
import '@material/checkbox/dist/mdc.checkbox.min.css';
import '@material/elevation/dist/mdc.elevation.min.css';
import '@rmwc/textfield/styles';

import { ThemeProvider } from '@rmwc/theme';

import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { CssBaseline, Grid } from "@material-ui/core";

// Global CSS
import './App.css';

// MUI Themes
import NaturgyTheme from './themes/Naturgy';

// Screens
import Home from './screens/home/HomeScreen';
import Quiz from './screens/quiz/QuizScreen';
import Rank from './screens/ranking/RankingScreen';
import Admin from './screens/admin/AdminScreen';

// Firebase
import * as firebase from "firebase";
import {
	FirebaseAuthProvider
} from "@react-firebase/auth";


const firebaseConfig = {
  "projectId": "coronaquiz",
  "appId": "1:931066744308:web:f536d01f622016b04bfda1",
  "databaseURL": "https://coronaquiz.firebaseio.com",
  "storageBucket": "coronaquiz.appspot.com",
  "locationId": "europe-west",
  "apiKey": "AIzaSyDUTtjia9AdjVvNFy4qbWuhe0zFQWSdrrM",
  "authDomain": "coronaquiz.firebaseapp.com",
  "messagingSenderId": "931066744308",
  "measurementId": "G-4WJB443M1H"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


function App() {
  return (
    <StylesProvider injectFirst>
        <MuiThemeProvider theme={NaturgyTheme}>
          <CssBaseline />
        <ThemeProvider
          options={{ 
            textPrimaryOnBackground: 'white',
            textSecondaryOnBackground: 'greenyellow',
            textPrimaryOnLight: 'greenyellow',
            textSecondaryOnLight: 'greenyellow',
            primary: 'greenyellow',
            secondary: 'white',
            onSurface: 'green' }
          }
        >
        <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
          <Grid container className="App">
            <Router>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/admin" component={Admin} />
                <Route exact path="/quiz/:id" component={Quiz} />
                <Route exact path="/rank/:id" component={Rank} />
              </Switch>
            </Router>
          </Grid>
        </FirebaseAuthProvider>
      </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
