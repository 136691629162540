import React from 'react';
import { Button } from '@material-ui/core';

import googleLogo from './google-icon.png';

function Login(props : any) {
	return (
		<Button variant="outlined"
				color="primary"
				onClick={props.onClick}
				endIcon={<img src={googleLogo} />}>
			Accede con Google
		</Button>
	);
}

export default Login;
