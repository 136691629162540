
import React from 'react';

// Theme & Material UI
import { withTheme, Theme } from '@material-ui/core/styles';
import { Grid, Box, Typography as TY, Button } from '@material-ui/core';

interface IProps {
	theme: Theme;
}

class SeparatorComponent
    extends React.Component<IProps>
{
    render() {

        return (
            <Box minHeight='20px'>
                {this.props.theme.separatorImage ? <img src={this.props.theme.separatorImage} /> : <hr/>}
            </Box>
        );
    }
}

export default withTheme(SeparatorComponent);