
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

import {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey as gray,
    blueGrey as blueGray
} from "@material-ui/core/colors";

import camelCase from "camelcase";
import { PaletteColorOptions } from '@material-ui/core/styles/createPalette';

import logo from './naturgy_assets/img/logo.png';
import separator from './naturgy_assets/img/separator.png';

import FontSFThin from './naturgy_assets/fonts/sf-compact-display-thin-58646eb43a785.woff';
import FontSFMedium from './naturgy_assets/fonts/sf-compact-display-medium-5864711817c30.woff';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        primaryColor: PaletteColorOptions
        secondaryColor: PaletteColorOptions
        dark: boolean
        logo?: any
        separatorImage?: any
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        primaryColor: PaletteColorOptions
        secondaryColor: PaletteColorOptions
        dark: boolean
        logo?: any
        separatorImage?: any
    }
}

const colors : any = {
    red: {
        id: "red",
        name: "Red",
        import: red,
    },

    pink: {
        id: "pink",
        name: "Pink",
        import: pink,
    },

    purple: {
        id: "purple",
        name: "Purple",
        import: purple,
    },

    deepPurple: {
        id: "deep-purple",
        name: "Deep Purple",
        import: deepPurple,
    },

    indigo: {
        id: "indigo",
        name: "Indigo",
        import: indigo,
    },

    blue: {
        id: "blue",
        name: "Blue",
        import: blue,
    },

    lightBlue: {
        id: "light-blue",
        name: "Light Blue",
        import: lightBlue,
    },

    cyan: {
        id: "cyan",
        name: "Cyan",
        import: cyan,
    },

    teal: {
        id: "teal",
        name: "Teal",
        import: teal,
    },

    green: {
        id: "green",
        name: "Green",
        import: green,
    },

    lightGreen: {
        id: "light-green",
        name: "Light Green",
        import: lightGreen,
    },

    lime: {
        id: "lime",
        name: "Lime",
        import: lime,
    },

    yellow: {
        id: "yellow",
        name: "Yellow",
        import: yellow,
    },

    amber: {
        id: "amber",
        name: "Amber",
        import: amber,
    },

    orange: {
        id: "orange",
        name: "Orange",
        import: orange,
    },

    deepOrange: {
        id: "deep-orange",
        name: "Deep Orange",
        import: deepOrange,
    },

    brown: {
        id: "brown",
        name: "Brown",
        import: brown,
    },

    gray: {
        id: "gray",
        name: "Gray",
        import: gray,
    },

    blueGray: {
        id: "blue-gray",
        name: "Blue Gray",
        import: blueGray,
    },
};

const getColor = (colorId: string) : any => {
    if (!colorId) {
        return null;
    }

    colorId = camelCase(colorId);

    return colors[colorId] as object;
};

const defaultPrimaryColor = {
    main: '#E97403'
};
const defaultSecondaryColor = {
    main: '#fff'
};

const defaultDark = false;

const SFThin = {
    fontFamily: 'SF Compact Display Thin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
        local('SF Compact Display Thin'),
        url(${FontSFThin}) format('woff')
    `
};

const SFMedium = {
    fontFamily: 'SF Compact Display Medium',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
        local('SF Compact Display Medium'),
        url(${FontSFMedium}) format('woff')
    `
};

const theme = createMuiTheme({
    
    typography: {
        allVariants: {
            fontFamily:'SF Compact Display Medium',
            color: '#004A87'
        }
    },

    palette: {
        primary: defaultPrimaryColor,
        secondary: defaultSecondaryColor,
        type: defaultDark ? "dark" : "light",
    },

    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [SFThin, SFMedium],
                body: {
                    background: 'linear-gradient(180deg, white, rgb(226, 238, 255) 50%)'
                }
            }
        },
        MuiButton: {
            root: {
                padding: '16px 16px',
                borderRadius: '15px'
            },
            outlined: {
                padding: '16px 16px',
                borderRadius: '15px'
            },
            outlinedSecondary: {
                color: '#004A87',
                boxShadow: '0px 6px 10px #00000044',
            }
        }
    },

    primaryColor: defaultPrimaryColor,
    secondaryColor: defaultSecondaryColor,
    dark: defaultDark,
    logo,
    separatorImage: separator
});

export default theme;



