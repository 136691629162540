import React from 'react';
import { RouteComponentProps } from 'react-router-dom';


// Parse SDK
import Parse from 'parse';

// Ignore missing types for this lib
// @ts-ignore
import ReactMomentCountDown from 'react-moment-countdown';

// Custom components
import QuestionComponent from './com/QuestionComponent';

// RMWC
import { Typography as TY, Grid, Box } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';


interface IMatchParams {
    id: string;
}

interface IState {
	quiz: any;
	question: any;
}

interface IProps extends RouteComponentProps<IMatchParams> {
	theme: Theme;
}

class HomeScreen extends React.Component<IProps, {}> {

	state : IState;
	quizUnsubscribe : any = null;

	constructor(props : IProps) {

		// Required step: always call the parent class' constructor
		super(props);
	
		// Set the state directly. Use props if necessary.
		this.state = {
			question: null,
			quiz: null
		}

	}

	setupQuizSubscription(quizQ : Parse.Query) {

        quizQ.subscribe().then((sub : Parse.LiveQuerySubscription) => {

			this.quizUnsubscribe = sub;

			sub.on('update', async (quiz) => {

				// This is called when something changes inside the quiz,
                // we have to update the screen with the next question
				const quizData = quiz.attributes;

				if (quizData.status != 'running')
				{
					// if finished, send to ranking
					if (quizData.status == 'calc' || quizData.status == 'finished')
						return this.props.history.push(`/rank/${quiz.id}`);

					// navigate away from here, we're done
					return this.props.history.push('/');
				}

				if (!quiz.get('currentQuestion'))
					return;

				this.setState({
					quiz: {...quizData, id: quiz.id}
				});
				
				
				const currentQuestion = await quiz.get('currentQuestion').fetch();

				this.setState({
					question: {...currentQuestion.attributes, id: currentQuestion.id}
				});
            
			});
		});
	}

	componentDidMount() {

		if (this.props.match.params.id === '' || !Parse.User.current())
			return this.props.history.replace('/');

		const quizO = Parse.Object.extend('Quiz');
		const quizQ = new Parse.Query(quizO);
		quizQ.equalTo('objectId', this.props.match.params.id);

		// make sure the quiz exists, or send home
		quizQ.first().then(async (quiz : any) => {

			if (quiz)
			{

				if (!quiz)
					return this.props.history.replace('/');

				const quizStatus = quiz.get('status');
				
				/*if (quizStatus == 'calc' || quizStatus == 'finished')
					return this.props.history.push(`/rank/${quiz.id}`); */

				if (!quiz.get('currentQuestion'))
					return this.props.history.replace('/');


				this.setState({
					quiz: {...quiz.attributes, id: quiz.id}
				});

				
				const currentQuestion = await quiz.get('currentQuestion').fetch();

				if (currentQuestion)
				{
					this.setState({
						question: {...currentQuestion.attributes, id: currentQuestion.id}
					});

					
				}

				this.setupQuizSubscription(quizQ);
			}
		})
		.catch((e) => {
			console.error(e);
			this.props.history.replace('/');
		})

	}

	componentWillUnmount() {
		if (this.quizUnsubscribe)
			this.quizUnsubscribe.unsubscribe();
	}
	
	render() {
		return (
			<>
				<Grid justify="center" container className="Question-Background">
					<Grid item xs={12} md={6}>
						<Box display="flex"
							justifyContent="center"
							alignItems="center"
							minHeight='20vh'><img src={this.props.theme.logo} className="App-logo" />
							</Box>
						<Box>
							{this.state.question ? 
								<QuestionComponent
									history={this.props.history}
									key={this.state.question.id}
									question={this.state.question}
									quiz={this.state.quiz}
								/> :
								<TY variant="h5">Cargando pregunta...</TY>
							}
						</Box>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withTheme(HomeScreen);
