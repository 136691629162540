import React from 'react';
import { History, LocationState } from "history";

// Auth
import Parse from 'parse';

// Ignore missing types for this lib
// @ts-ignore
import ReactMomentCountDown from 'react-moment-countdown';

// RMWC
import { Typography as TY } from '@rmwc/typography';
import { Grid, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';

import { TextField } from '@rmwc/textfield';


interface IState {
    resetQuizId: string;
    scheduleQuizId: string;
    scheduleTimestamp: string;
    requestResult: string;
}

interface IProps {
	history: History<LocationState>;
}

export default class AdminScreen extends React.Component<IProps, IState> {


	constructor(props : IProps) {

		// Required step: always call the parent class' constructor
		super(props);
	
		// Set the state directly. Use props if necessary.
		this.state = {
            resetQuizId: '',
            scheduleQuizId: '',
            scheduleTimestamp: '',
            requestResult: ''
		}
    }
    
    componentWillMount() {

        // If not logged in and not admin, navigate away

        if (!Parse.User.current())
            return this.props.history.replace('/');
            
        new Parse.Query(Parse.Role).equalTo('users', Parse.User.current() ).first()
            .then((role) => {
                if (role && role.getName() !== 'admin')
                    return this.props.history.replace('/');

            console.log('Recognized admin with id ' + Parse.User.current()?.id);
        });
    }

	componentDidMount() {
		
	}

	componentWillUnmount() {
		
    }
    
    resetQuiz() {
        if (!this.state.resetQuizId ||this.state.resetQuizId.trim() === '')
            alert('enter a valid quiz id first');
        else
        {
            Parse.Cloud.run('resetQuiz', {quizId: this.state.resetQuizId})
                .then((r) => {
                    this.setState({requestResult: ''+ (r || 'OK')});
                })
                .catch((e) => {
                    this.setState({requestResult: ''+ e});
                });
        }
    }

    scheduleQuiz() {

        if (!this.state.scheduleQuizId ||this.state.scheduleQuizId.trim() === '')
            return alert('enter a valid quiz id first');

        if (!this.state.scheduleTimestamp ||this.state.scheduleTimestamp.trim() === '')
            return alert('enter a valid quiz timestamp first');


        Parse.Cloud.run('scheduleQuiz', {quizId: this.state.scheduleQuizId, quizTimestamp: this.state.scheduleTimestamp})
            .then((r) => {
                this.setState({requestResult: ''+ (r || 'OK')});
            })
            .catch((e) => {
                this.setState({requestResult: ''+ e});
            });
    }    

	render() {
		return (
            <>
			<header className="App-header-admin">
				<TY use="headline2">Corona Trivia</TY>
				<TY use="button">Endure quarantine by taking a live quiz</TY>
			</header>
            <main style={{justifyContent:'center'}}>
				<Grid fixedColumnWidth={true}>
					<GridCell span={3}></GridCell>
					<GridCell span={6} style={{alignContent:'center'}}>
						<div>
                            <Button raised onClick={() => this.resetQuiz()}>Reset Quiz</Button><br/>
                            <TextField label="Quiz ID" onChange={(v) => this.setState({resetQuizId: v.currentTarget.value})} value={this.state.resetQuizId}></TextField></div>
                        <hr />
                        <div>
                                <Button raised onClick={() => this.scheduleQuiz()}>Schedule Quiz</Button><br/>
                                <TextField  label="Quiz Timestamp" type="datetime-local"
                                    onChange={(v) => this.setState({scheduleTimestamp: v.currentTarget.value})} value={this.state.scheduleTimestamp}></TextField><br/>
                                <TextField  label="Quiz ID"
                                    onChange={(v) => this.setState({scheduleQuizId: v.currentTarget.value})} value={this.state.scheduleQuizId}></TextField>
                        </div>
                        <hr/>
                        <code>
                            {this.state.requestResult}
                        </code>
					</GridCell>
					<GridCell span={3}></GridCell>
				</Grid>
			</main>
            </>
		);
	}
}