import React from 'react';
import moment from 'moment';
import { History, LocationState } from "history";

// Material UI
import { Typography as TY, Button, List, ListItem, Box, CircularProgress } from '@material-ui/core';

// Parse
import Parse from 'parse';

// Ignore missing types for this lib
// @ts-ignore
import ReactMomentCountDown from 'react-moment-countdown';

import ResultComponent from './ResultComponent';

interface IState {
    answer: string;
    finished: boolean;
    isLocked: boolean;
    isSpectactor: boolean;
    circularCompletion: number;
}

interface IProps {
    history: History<LocationState>;
    quiz: any;
    question: any;
}

export default class QuestionComponent
    extends React.Component<IProps, IState> {

    questionUnsubscribe: any = null;

    answerObject = Parse.Object.extend('Answer');
    questionObject = Parse.Object.extend('Question');
    quizObject = Parse.Object.extend('Quiz');

    constructor(props : IProps) {
        
        super(props);

        this.state = {
            answer: '',
            finished: false,
            isLocked : true,
            isSpectactor: false,
            circularCompletion: 100
        }
    }

    componentDidMount()
    {   
        const questionQ = new Parse.Query(this.questionObject);
        
        questionQ.equalTo('objectId', this.props.question.id);
        questionQ.limit(1);

        questionQ.subscribe().then(async (sub) => {

            this.questionUnsubscribe = sub;
            
            sub.on('update', (question) => {
                // this performs the switch to the results screen
                if (question.get('finishedAt'))
                    this.markAsFinished();
            });
        });

        // Fetch and load his current answer if any
        this.loadUserAnswerFromDatabase();

        // Check immediately whether it's finished
        if (this.props.question.finishedAt)
            this.markAsFinished();
        else
            this.unlockuserIfInPlay();
    }

    componentWillUnmount()
    {
        if (this.questionUnsubscribe)
            this.questionUnsubscribe.unsubscribe();
    }

    private async markAsFinished()
    {
        this.setState({
            finished: true
        });
    }

    private async loadUserAnswerFromDatabase() {
        const answerQuery = new Parse.Query(this.answerObject);
        
        const questionWrapper = new this.questionObject();
        questionWrapper.id = this.props.question.id;
        
        answerQuery.equalTo('user', Parse.User.current());
        answerQuery.equalTo('question', questionWrapper);
        
        const userAnswer = await answerQuery.first();
        if (userAnswer)
            this.setState({answer:userAnswer.get('answer')});
    }

    private unlockuserIfInPlay()
    {   
        // Unlock user if has not replied or still at play
        Parse.Cloud.run('isUserStillInPlay', {
            quizId: this.props.quiz.id,
        })
        .then((result) => {
            if (result === true) {

                this.setState({
                    isLocked: false
                });
            }
            else
                this.setState({
                    isSpectactor: true
                });
        });
    }

    async setAnswer(answer: string)
    {
        // If the user is locked out of the quiz, prevent selection
        if (this.state.isLocked)
            return;

        // Once selected, disallow selection
        if (this.state.answer !== '')
            return;
        
        const questionWrapper = new this.questionObject();
        questionWrapper.id = this.props.question.id;

        const quizWrapper = new this.quizObject();
        quizWrapper.id = this.props.quiz.id;

        const answerWrapper = new this.answerObject();
    
        
        answerWrapper.set('user', Parse.User.current());
        answerWrapper.set('question', questionWrapper);
        answerWrapper.set('quiz', quizWrapper);
        answerWrapper.set('answer', answer);

        const saveResult = await answerWrapper.save();
        console.log(saveResult);

        // TODO: do not set answer on error saving!
        this.setState({answer});
    }

    onCounterTick(countdown : number)
    {
        const totalSeconds = moment(this.props.question.willFinishAt).diff(moment(this.props.question.startedAt), 'seconds');
        const remainingSeconds = moment(this.props.question.willFinishAt).diff(moment(), 'seconds');

        console.log('totalSeconds', totalSeconds);
        console.log('remainingSeconds', remainingSeconds);

        this.setState({
            circularCompletion: (remainingSeconds / totalSeconds) * 100
        });
    }

    render() {

        if (this.state.finished)
            return <ResultComponent
                        history={this.props.history}
                        quiz={this.props.quiz}
                        question={this.props.question}
                        answer={this.state.answer}
                        isSpectator={this.state.isSpectactor}
                    />;

        return(
            <Box>
                <Box minHeight='30vh' alignItems="center" justifyContent="center" display="flex">
                    <Box>
                        <Box paddingX={5}><TY variant="h6">{this.props.question.text}</TY></Box>
                        <Box>
                            <Box width='100px' height='100px' margin="auto">
                                <TY variant="h5" className="Question-Progress-Text"><b>
                                <ReactMomentCountDown
                                    toDate={moment(this.props.question.willFinishAt)}
                                    targetFormatMask='s'
                                    onCountdownEnd={() => this.markAsFinished()}
                                    onTick={(countdown : any) => this.onCounterTick(countdown)}
                                    /></b></TY>
                                    <CircularProgress
                                        className="Question-Progress"
                                            size={100}
                                            variant="static"
                                            value={this.state.circularCompletion}
                                        />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="QuestionBox"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        p={5} >
                            <List>
                                <ListItem key="a" onClick={() => this.setAnswer('a')}>
                                    <Button 
                                        fullWidth
                                        variant={this.state.answer === 'a' ? 'contained' : 'outlined'}
                                        color={this.state.answer === 'a' ? 'primary' : 'secondary'}>A) {this.props.question.reply_a}</Button>
                                </ListItem>
                                <ListItem key="b" onClick={() => this.setAnswer('b')}>
                                    <Button
                                        fullWidth
                                        variant={this.state.answer === 'b' ? 'contained' : 'outlined'}
                                        color={this.state.answer === 'b' ? 'primary' : 'secondary'}>B) {this.props.question.reply_b}</Button>
                                </ListItem>
                                <ListItem key="c" onClick={() => this.setAnswer('c')}>
                                    <Button
                                        fullWidth
                                        variant={this.state.answer === 'c' ? 'contained' : 'outlined'}
                                        color={this.state.answer === 'c' ? 'primary' : 'secondary'}>C) {this.props.question.reply_c}</Button>
                                </ListItem>
                                <ListItem key="d" onClick={() => this.setAnswer('d')}>
                                    <Button
                                        fullWidth
                                        variant={this.state.answer === 'd' ? 'contained' : 'outlined'}
                                        color={this.state.answer === 'd' ? 'primary' : 'secondary'}>D) {this.props.question.reply_d}</Button>
                                </ListItem>
                            </List>
                        {this.state.isSpectactor ? <TY variant="body2">¡No puedes contestar, has sido eliminad@!</TY> : null}
                </Box>
			</Box>
        );
    }
}