import React from 'react';
import _ from 'lodash';
import { History, LocationState } from "history";
import moment from 'moment';

// @ts-ignore
import ReactMomentCountDown from 'react-moment-countdown';

// RMWC
import { Typography as TY, Button, List, ListItem, Box, CircularProgress } from '@material-ui/core';

// Parse
import Parse from 'parse';


interface IState {
    results: any;
    willDisplayResultsUntil: any;
    circularCompletion: number;
}

interface IProps {
    history: History<LocationState>;
    quiz: any;
    question: any;
    answer: string;
    isSpectator: boolean;
}

export default class ResultComponent
    extends React.Component<IProps, IState> {

    questionObject = Parse.Object.extend('Question');
    resultInterval : any = null;


    constructor(props : IProps) {
        
        super(props);

        this.state = {
            results: null,
            willDisplayResultsUntil: null,
            circularCompletion: 100
        }
    }

    componentDidMount()
    {
        this.resultInterval = setInterval(this.pollResults.bind(this), 1500);

        console.log('We\'re visible until: ' + this.props.question.willDisplayResultsUntil);
    }

    private async pollResults()
    {
        // Read results already counted by cloud, this is fast!
        const questionQuery = new Parse.Query(this.questionObject);
        questionQuery.equalTo('objectId', this.props.question.id);
        questionQuery.select('results', 'willDisplayResultsUntil');
        questionQuery.limit(1);
        questionQuery.first().then((question) => {
            
            // Mistake...
            if (!question)
                return this.props.history.replace('/');

            if (!question.get('finishedAt'))
                return;

            clearInterval(this.resultInterval);

            this.setState({
                willDisplayResultsUntil: question.get('willDisplayResultsUntil'),
                results: question.get('results')
            });
        });
    }

    onCounterTick(countdown : number)
    {
        const totalSeconds = moment(this.state.willDisplayResultsUntil).diff(moment(this.props.question.willFinishAt), 'seconds');
        const remainingSeconds = moment(this.state.willDisplayResultsUntil).diff(moment(), 'seconds');

        this.setState({
            circularCompletion: (remainingSeconds / totalSeconds) * 100
        });
    }    

    render() {

        if (this.state.results == null)
            return <TY variant="h4">Calculando...</TY>;

        return(
            <Box>
                <Box minHeight='30vh' alignItems="center" justifyContent="center" display="flex">
                    <Box>
                        <Box paddingX={5}><TY variant="h6">{this.props.question.explanation ?? 'Resultado pregunta'}</TY></Box>
                        {this.state.willDisplayResultsUntil &&
                        <Box>
                            <Box width='100px' height='100px' margin="auto">
                                <TY variant="h5" className="Question-Progress-Text"><b>
                                
                                    <ReactMomentCountDown
                                        toDate={moment(this.state.willDisplayResultsUntil)}
                                        onTick={(counter: any) => this.onCounterTick(counter)}
                                        targetFormatMask='s'
                                    /></b></TY>
                                    <CircularProgress
                                        className="Question-Progress"
                                            size={100}
                                            variant="static"
                                            value={this.state.circularCompletion}
                                        />
                            </Box>
                        </Box>}
                    </Box>
                </Box>
                <Box className="QuestionBox"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        p={5} >
                            <Box style={{display: this.props.isSpectator ? 'none' : 'auto'}}>
                            { this.props.answer === '' ? <TY variant="h5">🤷🏻‍♂️ ¡Demasiado tarde!</TY> :
                                this.props.answer == this.props.question.solution ?
                                    <TY variant="h5">👍🏻¡Correcto!</TY> :
                                    <TY variant="h5">❌¡Eliminad@!</TY>
                            }
                            </Box>
                            <List>
                                <ListItem key="a">
                                    <Button 
                                        className={this.props.question.solution != 'a' && this.props.answer == 'a' ? 'Button-wrong-answer' : ''} 
                                        fullWidth
                                        variant={this.props.question.solution === 'a' ? 'contained' : 'outlined'}
                                        color={this.props.question.solution === 'a' ? 'primary' : 'secondary'}>A) {this.props.question.reply_a}</Button>
                                    {/*this.state.results['a'] || 0*/}
                                </ListItem>
                                
                                <ListItem key="b">
                                    <Button
                                        className={this.props.question.solution != 'b' && this.props.answer == 'b' ? 'Button-wrong-answer' : ''} 
                                        fullWidth
                                        variant={this.props.question.solution === 'b' ? 'contained' : 'outlined'}
                                        color={this.props.question.solution === 'b' ? 'primary' : 'secondary'}>B) {this.props.question.reply_b}</Button>
                                    {/*this.state.results['b'] || 0*/}
                                </ListItem>
                                <ListItem key="c">
                                    <Button
                                        fullWidth
                                        className={this.props.question.solution != 'c' && this.props.answer == 'c' ? 'Button-wrong-answer' : ''} 
                                        variant={this.props.question.solution === 'c' ? 'contained' : 'outlined'}
                                        color={this.props.question.solution === 'c' ? 'primary' : 'secondary'}>C) {this.props.question.reply_c}</Button>
                                    {/*this.state.results['c'] || 0*/}
                                </ListItem>
                                <ListItem key="d">
                                    <Button
                                        className={this.props.question.solution != 'd' && this.props.answer == 'd' ? 'Button-wrong-answer' : ''} 
                                        fullWidth
                                        variant={this.props.question.solution === 'd' ? 'contained' : 'outlined'}
                                        color={this.props.question.solution === 'd' ? 'primary' : 'secondary'}>D) {this.props.question.reply_d}</Button>
                                    {/*this.state.results['d'] || 0*/}
                                </ListItem>
                            </List>
                            <TY variant="body2">{this.props.question.solution in this.state.results ? `Pasan ronda ${this.state.results[this.props.question.solution]}` : '¡Todos eliminados!'}</TY>
                </Box>
			</Box>
        );
    }
}