import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

import Parse from 'parse';

Parse.initialize('ztjRDZyNGwyQCIZ35tLqXhnvO9fOYTy1Gl5c9d0S');
Parse.serverURL = 'https://trivia.yondbee.com/parse';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onRegister: (registration) => {
    console.log('Signaling Firebase to use our SW success');

    if (firebase.messaging.isSupported())
    {
      firebase.messaging().useServiceWorker(registration);
      firebase.messaging().usePublicVapidKey('BEpGQLBs8mQme7n39pMVOL9C50dO6gfueLrTopdsUIcVUBo2fzQBU7i0swF8c-1mwh2fFvTjyWRqfLYbox7VxlM');
      window.dispatchEvent(new Event('firebaseMessagingReady'));

      // @ts-ignore
      window.firebaseMessagingReady = true;
    }
  }
});
