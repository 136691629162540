import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Parse SDK
import Parse from 'parse';


// Material UI
import { Typography as TY, Button, List, ListItem, Box, Grid, Paper } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';

interface IMatchParams {
	id: string;
	
}

interface IState {
	results: any;
	quiz : any;
	totalUsers: number;
}

interface IProps extends RouteComponentProps<IMatchParams> {
    theme: Theme;
}

class RankingScreen extends React.Component<IProps, {}> {

	state : IState;
	loadedItems = 0;
	loadChunk = 100;
	quizSubscribe: any;

	constructor(props : IProps) {

		// Required step: always call the parent class' constructor
		super(props);
	
		// Set the state directly. Use props if necessary.
		this.state = {
			quiz: null,
			results: [],
			totalUsers: 0
		}

	}

	async loadQuiz() {

		if (this.props.match.params.id === '' || !Parse.User.current())
			return this.props.history.replace('/');

		const quizO = Parse.Object.extend('Quiz');
		const quizQ = new Parse.Query(quizO);
		quizQ.equalTo('objectId', this.props.match.params.id);
		quizQ.exists('finishedAt');
		// make sure the quiz exists, or send home
		quizQ.first().then(async (quiz : any) => {

			if (quiz)
			{
				// only show finished quizzes
				if (!quiz)
					return this.props.history.replace('/');


				this.setState({
					quiz: {...quiz.attributes, id: quiz.id}
				});

				setTimeout(() => this.loadMore(), 150);

				quizQ.subscribe().then((sub) => {

					this.quizSubscribe = sub;

					sub.on('update', (quiz) => {
						this.setState({
							quiz: {...quiz.attributes, id: quiz.id}
						});
					});

				});
			}
		})
		.catch((e) => {
			console.error(e);
			this.props.history.replace('/');
		})
	}

	async loadMore() {

		const rankObject = Parse.Object.extend('Ranking');
		const rankQuery = new Parse.Query(rankObject);

		rankQuery.skip(this.loadedItems);

		rankQuery.limit(this.loadChunk);
		rankQuery.ascending('rank');
		rankQuery.include('user');

		// @ts-ignore
		rankQuery.withCount();

		const results = await rankQuery.find();

		console.log('results', results);

		// concatenated results
		// @ts-ignore
		const concatenatedResults = _.concat(this.state.results, _.map(results.results, (v) => v.get('user')));

		console.log('concatenatedResults', concatenatedResults);

		this.setState({
			// @ts-ignore
			results: _.concat(this.state.results, concatenatedResults),
			// @ts-ignore
			totalUsers: results.count
		});

		// @ts-ignore
		if (results.results)
		{
			// @ts-ignore
			this.loadedItems = this.loadedItems + results.results.length;
		}

    }

	componentDidMount() {
		this.loadQuiz();
	}

	
	render() {
		let counter = 1;
		return (
			<Grid justify="center" container className="Question-Background">
					<Grid item xs={12} md={6}>
						<Box display="flex"
							justifyContent="center"
							alignItems="center"
							minHeight='20vh'><img src={this.props.theme.logo} className="App-logo" />
							</Box>
						<Box className="RannkingBox"
							justifyContent="flex-start"
							display="flex"
							flexDirection="column"
							p={5} >
							<Box mb={2}>
								{ !this.state.quiz || this.state.quiz.status == "calc" ?
									<TY variant="h4">Calculando resultados...</TY> : 
									<TY variant="h4" align="left"><b>Clasificación final</b></TY>
								}
							</Box>
							{this.state.quiz && this.state.quiz.status === 'finished' && this.state.results.length === 0 ?
							<TY variant="h5">¡NO HAY GANADORES!</TY> : null}
							{this.state.results.map((row : any) => {
								return (
									<>
										<List>
											<ListItem key="{row.id}">
												<Button variant="contained" color="secondary" fullWidth>
													<Grid container>
														<Grid item xs={2}>
															<TY variant="h6" align="center">{counter++}</TY>
														</Grid>
														<Grid item xs={10}>
															<TY variant="h6" align="right" style={{textTransform:'none'}}>{row.get('displayName')}</TY>
														</Grid>
													</Grid>
												</Button>
											</ListItem>
										</List>
									</>
							)
							})}
							{ this.state.results.length > 0 && false ? <TY variant="h5">{this.state.totalUsers} Ganadores</TY> : null}
							{ this.state.totalUsers != this.state.results.length ? <div><Button color="primary" onClick={() => this.loadMore()}>Ver más</Button></div> : null}
							<div><Button onClick={() => this.props.history.push('/')} variant="outlined">Volver</Button></div>
						</Box>
					</Grid>
				</Grid>
		);
	}
}

export default withTheme(RankingScreen);