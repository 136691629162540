import React from 'react';
import mom from 'moment';
import { History, LocationState } from "history";

// Theme & Material UI
import { withTheme, Theme } from '@material-ui/core/styles';
import { Grid, Box, Typography as TY, Button } from '@material-ui/core';
import { Alarm as AlarmIcon, ExitToApp as Logout } from '@material-ui/icons';

// Auth
import * as firebase from 'firebase';
import Parse from 'parse';

// Components
import Login from './com/Login';
import Separator from '../common/SeparatorComponent';

// Ignore missing types for this lib
// @ts-ignore
import ReactMomentCountDown from 'react-moment-countdown';

const LOCAL_DOMAINS = [
	'',
	'localhost',
	'127.0.0.1',
	'::1',
	'0.0.0.0',
];


interface IState {
	nextQuiz: any;
	currentUser: Parse.User | undefined;
	notifiable: boolean;
	isTokenLoaded: boolean;
}

interface IProps {
	history: History<LocationState>;
	theme: Theme;
}

class HomeScreen extends React.Component<IProps, {}> {

	state : IState;
	quizUnsubscribe : any = null;

	constructor(props : IProps) {

		// Required step: always call the parent class' constructor
		super(props);
	
		// Set the state directly. Use props if necessary.
		this.state = {
			nextQuiz: null,
			currentUser: Parse.User.current(),
			isTokenLoaded: false,
			notifiable: false
		}
	}

	componentDidMount() {

		this.observeFirebaseUser();
		this.loadCurrentQuiz();

		if (this.hasNotificationPermission())
		{
			//@ts-ignore
			if (window.firebaseMessagingReady)
				this.loadNotificationsToken();
			else
			{
				window.addEventListener('firebaseMessagingReady', () => {
					this.loadNotificationsToken();
				});
			}
		}
	}

	componentWillUnmount() {
		if (this.quizUnsubscribe)
			this.quizUnsubscribe.unsubscribe();
	}
	
	googleLogin() {
		const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().signInWithPopup(googleAuthProvider);
	};

	loadCurrentQuiz() {
		const Quiz = Parse.Object.extend("Quiz");
		const query = new Parse.Query(Quiz);
	
		query.ascending('start');
		query.containedIn('status', ['planned', 'running']);
		query.limit(1);

		query.first().then((res) => {

			if (!res)
				return;

			// if we found a quiz, set it as current object
			this.setState({
				nextQuiz: {...res?.attributes, id: res?.id}
			})

		});


		// Capture live updates in case quiz starts
		query.subscribe().then((sub) =>{

			this.quizUnsubscribe = sub;
		
			sub.on('update', (quiz) => {
				console.log('quiz updated', quiz);
				if (quiz)
					this.setState({
						nextQuiz: {...quiz?.attributes, id: quiz?.id}
					})
				else
					this.setState({
						nextQuiz: null
					})
			});

			sub.on('enter', (quiz) => {
				console.log('quiz entered query', quiz);
				if (quiz)
					this.setState({
						nextQuiz: {...quiz?.attributes, id: quiz?.id}
					})
				else
					this.setState({
						nextQuiz: null
					})
			});

			sub.on('leave', (quiz) => {
				this.setState({
					nextQuiz: null
				})
			});

			sub.on('create', (quiz) => {
				if (quiz)
				this.setState({
					nextQuiz: {...quiz?.attributes, id: quiz?.id}
				})
			else
				this.setState({
					nextQuiz: null
				})
			});		
		});
	}

	observeFirebaseUser() {
		firebase.auth().onAuthStateChanged(async (user) => {

			if (!user)
			{
				console.log('no user on auth status changed');

				Parse.User.logOut().then(() => {
					this.setState({
						currentUser: null
					});
				});
				return;
			}

			const myAuthData = {
				id: user.uid,
				access_token: await user.getIdToken(true)
			};

			const parseUser = new Parse.User();
			await parseUser.linkWith('firebase', { authData: myAuthData });

			user.email && parseUser.set('email', user.email);
			user.displayName && parseUser.set('displayName', user.displayName);
			try {
				await parseUser.save();

				this.setState({
					currentUser: parseUser
				});
			} 
			catch (error)
			{
				alert('Unable to login, try again');
			}

			
		})
	}

	showQuiz(isLoggedIn : boolean) {
		if (this.state.nextQuiz !== null)
		{
			let nextQuiz = this.state.nextQuiz;

			if (nextQuiz.status === 'running' && isLoggedIn)
				this.props.history.push(`/quiz/${nextQuiz.id}`);

			return <Box>
					<Box><TY variant="h6">El próximo concurso empieza en</TY></Box>
					<Box><TY variant="h4"><b><ReactMomentCountDown key={nextQuiz.start}  toDate={mom(nextQuiz.start)} targetFormatMask='D \d, HH \h, mm \m, ss \s' /></b></TY></Box>
					<Box mt={2}>
						<TY variant="body1">
							Participa y llevate<br/>
							<u>{nextQuiz.prize}</u>
						</TY>
					</Box>
				</Box>;
		}
		else
			return <TY variant="h6">No hay concursos planificados de momento.</TY>
	}

	hasNotificationPermission() {

		if (!this.messagingSupported())
			return false;

		console.log('my current notification permission', Notification.permission)
		return Notification.permission === 'granted';
	}

	requestNotificationPermission() {

		if (!this.messagingSupported())
			return;
		
		if (Notification.permission === 'denied')
			return alert('You\'ve blocked notifications from browser, please re-enable them!');

		this.loadNotificationsToken();
	}

	messagingSupported() {
		return firebase.messaging.isSupported();
	}

	loadNotificationsToken() {

		if (!this.messagingSupported())
			return;

		firebase.messaging().getToken().then((currentToken) => {
			console.log('FCM token', currentToken);

			Parse.Cloud.run('subscribeToNotifications', {token : currentToken, test: LOCAL_DOMAINS.includes(window.location.hostname) });

			if (currentToken) {
				this.setState({
					notifiable: true,
					isTokenLoaded: true
				});
			} else {
				this.setState({
					notifiable: false,
					isTokenLoaded: true
				});
			}
		}).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			this.setState({
				notifiable: false,
				isTokenLoaded: true
			});
		});
	}

	async signout()
	{
		firebase.auth().signOut();
	}

	render() {
		let isSignedIn = this.state.currentUser != null;
		return (
			<Grid justify="center" container>
				<Grid item xs={12} md={6}>
					<Box display="flex" justifyContent="center" alignItems="center" minHeight='20vh'><img src={this.props.theme.logo} className="App-logo" /></Box>
					<Box>
						<Box minHeight='30vh' alignItems="center" justifyContent="center" display="flex">
							{this.showQuiz(isSignedIn)}
						</Box>
						<Box className="LoginBox"
								justifyContent="center"
								display="flex"
								flexDirection="column"
								p={5} >
							{isSignedIn ?
								<Box>
									<Box mb={2}><TY variant="h5">Ya has efectuado el acceso,<br/>¡vuelve cuando es el momento!</TY></Box>
									<Separator />
									<Box style={{
											display: this.hasNotificationPermission() || !this.messagingSupported() ? 'none' : 'auto'
										}}
										mb={2} mt={2}>
										<Button variant="contained" color="primary" fullWidth startIcon={<AlarmIcon />} onClick={() => this.requestNotificationPermission()}>¡Avísame!</Button>
									</Box>
									<Button variant="contained" color="secondary" fullWidth startIcon={<Logout />} onClick={() => this.signout()}>Desconectar</Button>
								</Box> :
								<Box>
									<TY variant="h5">Vuelve cuando es el momento o<br/>accede para recibir notificaciones.</TY>
									<Separator />
									<Login onClick={this.googleLogin.bind(this)}/>
								</Box>
							}
						</Box>
					</Box>
				</Grid>
			</Grid>
		);
	}
}

export default withTheme(HomeScreen);